import RegisterPage1 from "../../component/volunteer/Volunteerreg";
import Loginbg from "../../component/login/loginbg/welcome";

const VolunteerPage = () => {
    return (
        <>
            <RegisterPage1 />
            <Loginbg />
        </>
    );
};

export default VolunteerPage;
