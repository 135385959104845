import RegisterPage2 from "../../component/specialdevotee/specialdevotee";
import Loginbg from "../../component/login/loginbg/welcome";

const Special = () => {
    return (
        <>
            <RegisterPage2 />
            <Loginbg />
        </>
    );
};

export default Special;
