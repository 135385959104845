import ContactUs from "../../component/contact/Contactus";
import NavBar from "../../component/NavBarcomponent/navbar.jsx"

const Contact =() =>{
    return(
        <>
          <NavBar />
        <ContactUs />
       

        </>
    )
}

export default Contact