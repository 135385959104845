import NavBar from "../../component/NavBarcomponent/navbar.jsx"
import Festival from "../../component/festival/festival.jsx"
import Background from "../../component/bgcomponent/backfestival/bgfestival.jsx"
const home =() =>{

    return(
    <>
    <NavBar />
    <Background/>
    <Festival />
    </>

    )

}

export default home