import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Alert, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import './main.css';

const Uthchavam = () => {
  const [uthchavams, setUthchavams] = useState([]);
  const [newUthchavam, setNewUthchavam] = useState({
    poojaname: '',
    startdate: '',
    enddate: '',
    imageurls: []
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState({});

  useEffect(() => {
    axios
      .get('https://venkatesaperumal-backend.onrender.com/api/uthchavams')
      .then((response) => setUthchavams(response.data))
      .catch((error) => console.error('Error fetching uthchavams:', error));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUthchavam({ ...newUthchavam, [name]: value });
  };

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    const uploadedImageUrls = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('upload_preset', 'zpbashqc'); // Replace with your Cloudinary preset

      try {
        const response = await axios.post('https://api.cloudinary.com/v1_1/dsgdnskfj/image/upload', formData);
        uploadedImageUrls.push(response.data.secure_url);
      } catch (error) {
        console.error('Error uploading image', error);
      }
    }

    setNewUthchavam((prevData) => ({
      ...prevData,
      imageurls: [...prevData.imageurls, ...uploadedImageUrls]
    }));
  };

  const handleCreateUthchavam = async () => {
    try {
      await axios.post('https://venkatesaperumal-backend.onrender.com/api/uthchavams', newUthchavam);
      const response = await axios.get('https://venkatesaperumal-backend.onrender.com/api/uthchavams');
      setUthchavams(response.data);
      setNewUthchavam({
        poojaname: '',
        startdate: '',
        enddate: '',
        imageurls: []
      });
      setError('');
      setIsCreating(false);
    } catch (error) {
      console.error(error);
      setError('Error creating Uthchavam');
    }
  };

  const handleSend = async (id) => {
    try {
      await axios.post('https://venkatesaperumal-backend.onrender.com/api/send-images', {
        uthchavamId: id,
        groupType: selectedGroups[id] || 'devotee'
      });
      setSuccess('Message sent successfully!');

      // Auto-dismiss the alert after 3 seconds
      setTimeout(() => setSuccess(''), 3000);
    } catch (error) {
      console.error('Error sending messages:', error);
      setError('Failed to send messages');
    }
  };

  const handleDeleteUthchavam = async (id) => {
    try {
      await axios.delete(`https://venkatesaperumal-backend.onrender.com/api/uthchavams/${id}`);
      setUthchavams(uthchavams.filter((uthchavam) => uthchavam._id !== id));
    } catch (error) {
      console.error(error);
    }
  };

  const handleGroupChange = (id, group) => {
    setSelectedGroups({ ...selectedGroups, [id]: group });
  };

  return (
    <div>
      <h1 className="heading">Utchavam Management</h1>

      {/* Success Alert */}
      {success && (
        <Alert variant="success" onClose={() => setSuccess('')} dismissible>
          {success}
        </Alert>
      )}

      {/* Error Alert */}
      {error && (
        <Alert variant="danger" onClose={() => setError('')} dismissible>
          {error}
        </Alert>
      )}

      <Table striped bordered hover className="mt-3">
        <thead>
          <tr>
            <th>Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {uthchavams.map((uthchavam) => (
            <tr key={uthchavam._id}>
              <td>{uthchavam.poojaname}</td>
              <td>{new Date(uthchavam.startdate).toLocaleDateString()}</td>
              <td>{new Date(uthchavam.enddate).toLocaleDateString()}</td>
              <td>
                <div className="d-flex flex-row mr-2">
                  <Button
                    variant="danger"
                    onClick={() => handleDeleteUthchavam(uthchavam._id)}
                    className="threebtn"
                  >
                    Delete
                  </Button>
                  <div className="d-flex flex-row mr-2">
                    <Dropdown className="threebtn">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {selectedGroups[uthchavam._id]?.charAt(0).toUpperCase() +
                          selectedGroups[uthchavam._id]?.slice(1) || 'Devotee'}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => handleGroupChange(uthchavam._id, 'devotee')}>
                          Devotee
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleGroupChange(uthchavam._id, 'volunteer')}>
                          Volunteer
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleGroupChange(uthchavam._id, 'special')}>
                          Special Devotee
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Button
                      variant="primary"
                      onClick={() => handleSend(uthchavam._id)}
                      className="threebtn"
                    >
                      Send
                    </Button>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Button variant="outline-success" onClick={() => setIsCreating(!isCreating)}>
        {isCreating ? 'Cancel' : 'Add Festival'}
      </Button>

      {isCreating && (
        <Form className="mt-3">
          <Form.Group className="mb-3">
            <Form.Label>Utchavam Name</Form.Label>
            <Form.Control
              type="text"
              name="poojaname"
              value={newUthchavam.poojaname}
              onChange={handleInputChange}
              placeholder="Enter Pooja Name"
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              name="startdate"
              value={newUthchavam.startdate}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              name="enddate"
              value={newUthchavam.enddate}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Upload Images</Form.Label>
            <Form.Control type="file" multiple onChange={handleImageUpload} />
          </Form.Group>

          {newUthchavam.imageurls.length > 0 && (
            <div className="image-preview">
              {newUthchavam.imageurls.map((url, index) => (
                <img key={index} src={url} alt={`Upload Preview ${index + 1}`} />
              ))}
            </div>
          )}

          <Button variant="primary" onClick={handleCreateUthchavam}>
            Create
          </Button>
        </Form>
      )}
    </div>
  );
};

export default Uthchavam;
