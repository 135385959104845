import React from "react";
import Gallery from "../../component/gallery/gallery";
import NavBar from "../../component/NavBarcomponent/navbar.jsx"


const gallery =() =>{
    return (
        <>
        <NavBar />

        <Gallery/>
        
        </>
    )
}

export default gallery;

