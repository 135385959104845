import NavBar from "../../component/NavBarcomponent/navbar.jsx"
import Backgroundhistory from "../../component/bgcomponent/backhistory/bghistory.jsx"
import Pooja from "../../component/pooja/pooja.jsx"
const poojapage =() =>{

    return(
    <>

    <NavBar />
    <Backgroundhistory/>
    <Pooja />
    
    </>
    )

}

export default poojapage