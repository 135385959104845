import LoginPage from "../../component/login/LoginPage";
import Loginbg from "../../component/login/loginbg/welcome";

const login =() =>{
    return(
        <>
        <LoginPage />
        <Loginbg />
        </>
    )
}
export default login