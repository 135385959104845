import RegisterPage from "../../component/register/register"
import Loginbg from "../../component/login/loginbg/welcome";
const register =() =>{
    return(
        <>
        <RegisterPage />
        <Loginbg />
        </>
    )
}
export default register